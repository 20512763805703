<template>
	<div>
		<div class="mp2-bg pb-5">
			<div class="container pt-4 pb-4">
				<div class="row">
					<div class="col-12 pt-5">
						<h2>{{ $t('message.company.title') }}</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="container pt-5 pb-5 company">
			<div class="row pb-5">
				<div class="col-12 col-md-6 pb-md-0 pb-3">
					<div class="row">
						<div class="col-12 pb-3">
							<h3>{{ $t('message.company.title2') }}</h3>
						</div>
						<div class="col-12 pb-2">
							<p v-html="$t('message.company.text1')"></p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<img :src="require(`@/assets/images/pt/empresa_1.jpg`)" class="img-fluid " alt="">
				</div>
			</div>
			<div class="row pb-5">
				<div class="col-12">
					<div class="row">
						<div class="col-12 pb-md-0 pb-3">
							<h3>{{ $t('message.company.title5') }}</h3>
						</div>
						<div class="col-12">
							<p v-html="$t('message.company.text2')"></p>
						</div>
					</div>
				</div>
			</div>
			<div class="row pb-5">
				<div class="col-12 col-md-6 pb-2">
					<div class="row">
						<div class="col-12 pb-md-0 pb-3">
							<h3>{{ $t('message.company.title3') }}</h3>
						</div>
						<div class="col-12 pb-md-0 pb-3">
							<p v-html="$t('message.company.text3')"></p>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<img :src="require(`@/assets/images/pt/empresa_2.jpg`)" class="img-fluid" alt="">
				</div>
			</div>
		</div>
		<div class="mp-dark-bg">
			<div class="container">
				<WhyZone></WhyZone>
			</div>
		</div>

		<div class="mp-yellow-bg">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
							<div class="d-md-flex justify-content-md-start content">
								{{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{
									$t('message.services.contactus')
								}}!</span>
							</div>

							<div class="d-md-flex justify-content-md-end content">
								<a href="tel:+351 924 114 635"><i class="fas fa-phone mr-2"></i>+351 924 114 635</a>
								<router-link class="btn btn-primary mt-2 mt-md-0"
											 :to="{name:'contacts', params : {lang:lang}}">
									<i
										class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
								</router-link>
							</div>
						</div>
						<p class="text-right" style="font-size:0.75rem;font-weight:400;margin: 15px;">{{ $t('message.cost_mobile') }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>

<script>

import json     from '../../website-data.json';
import Location from '../components/Location';
import WhyZone  from '../components/WhyZone';

export default {
	name       : 'CompanyPage',
	components : {
		WhyZone,
		Location
	},
	data       : function () {
		return {
			json : json,
			lang : this.$route.params.lang
		};
	},
	methods    : {}
};
</script>
